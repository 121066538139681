
var ww = $(window).width();
var scale = window.devicePixelRatio;

// テーマディレクトリpathを取得
var path = '.';


(function () {

  // 画像エフェクト
  var canvas = document.getElementById("effect_logo");
  var cont = document.getElementById("logo_cont");
  var logoPath = path + "/assets/img/common/logo_w@2x.png";
  var logo_widht = Math.floor(ww * 0.23) * scale;
  var speed = 30;
  if (ww < 569) {
    // logoPath = path + "/assets/img/common/logo_sp.svg";
    logo_widht = Math.floor(ww * 0.21) * scale;
    speed = 300;
  } 
  if (canvas && cont) {

    canvas.width = logo_widht;
    // canvas.height = Math.floor(canvas.width * 0.317);
    canvas.height = Math.floor(canvas.width * 0.29);
    cont.style.width = canvas.width / scale + "px";
    cont.style.height = canvas.height / scale + "px";

    var ctx = canvas.getContext('2d');

    // console.log(scale, logo_widht, canvas.width, canvas.height, cont.style.width, cont.style.height)

    var bgImage = new Image();
    bgImage.onload = function () {

      var image = new Image();
      image.onload = function () {

        var ih = ww / this.naturalWidth * this.naturalHeight;

        var pxl = 0;
        var a = ww * 33 / 30000;


        setInterval(function () {
          ctx.clearRect(0, 0, canvas.width, canvas.height);
          ctx.globalCompositeOperation = 'source-over';
          ctx.drawImage(bgImage, -1 * pxl, 0, ww, ih);
          ctx.drawImage(bgImage, ww - pxl, 0, ww, ih);
          drawLogo(image);

          pxl += a;
          if (pxl >= ww) {
            pxl = 0;
          }
        }, 1000 / speed);

      };
      image.src = logoPath;

    };
    bgImage.src = path + '/assets/img/common/bg2.png';

  }


  var drawLogo = function (image) {

    ctx.globalCompositeOperation = 'destination-in';

    ctx.drawImage(image, 0, 0, canvas.width, canvas.height);
  };

})();